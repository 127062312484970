
  <template>
  <v-row justify="center">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <v-col cols="12" sm="12" md="12" lg="10">
      <v-card ref="form">
        <v-card-text>
          <v-card-title >
            <span class="headline">Update Endpoint Permission <span v-if="getRoleName()!=''">: {{getRoleName()}}</span></span>
          </v-card-title>
          <template>
            <form>
              <v-row no-gutters>
                <v-col cols="10" sm="10" md="10" class="my-4">
                  <!-- <v-checkbox
                   v-if="services.length"
                  ref="n"
                  color="primary"
                  label="Select All"
                  v-model="allSelected"
              ></v-checkbox> -->
              <!-- <input type="checkbox" class="my-4 mr-2 ml-1" label="Select All Services"
                              :checked="checkIfAllParentSelected()"
                              @click="selectAll($event)">Select All Services -->
                  <v-row
                    no-gutters
                    v-for="(service, serviceIndex) in services"
                    :key="service.id"
                  >
                    <v-col cols="10" sm="10" md="10" class="tree">
                      <nav class="tree-nav">
                        <div>
                          <details class="tree-nav__item is-expandable" open>
                            <summary class="tree-nav__item-title">
                              <input type="checkbox" :id="service.id" class="parentCheckbox mr-1"
                              :checked="checkIfAllChildrenSelected(service.id)"
                              @click="selectAllChildren($event, service.id)">
                              {{ service.name }}
                            </summary>
                            <div
                              v-for="(child, childIndex) in service.children"
                              :key="child.id"
                            >
                              <div>
                                <a class="tree-nav__item-title pl-2" style="color:#000;margin-left:15px;"
                                  ><i class="icon ion-ios-bookmarks"></i>
                                  <input
                                    type="checkbox"
                                    :id="service.id"
                                    name="selectedp"
                                    :value="child.selected_children"
                                    :checked="
                                      checkIfInselectedChildren(
                                        child.id,
                                        service.selected_children
                                      )
                                    "
                                    @click="
                                      selectedChild($event, service.id, child.id)
                                    "
                                  />
                                  <label for="vehicle1">
                                    {{ child.name }}</label
                                  >
                                  <br />
                                </a>
                              </div>
                            </div>
                          </details>
                        </div>
                      </nav>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row  justify="center" class="text-center mt-2 mb-2" v-if="services.length">
                <v-btn  justify="center" class="confirm_button mr-4 mt-5" @click="submitPermissions"> submit </v-btn>
              <v-btn depressed class=" mr-4 mt-5" color="default" @click="$router.push({ path: './../manage-role' });">
                Cancel
              </v-btn>
              </v-row>
            </form>
          </template></v-card-text
        ></v-card
      ></v-col
    ></v-row
  >
</template>




<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      valid: true,
      checkbox: false,
      isLoading: true,
      fullPage: true,
      selection: [],
      service_data: [],
      sector_permi: null,
      checked: false,
      services: [],
      selectedPermission: [],
      parentServices:[],
      operationData: [],
      checked_childId: [],
      roles:[],
      checked_parentId: [],
      allSelected: false,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  mounted() {
    //this.getResult(this.$route.params.id);
  },
  methods: {
    // checkIfAllParentSelected(){
    //   let parentCheckboxes =  document.getElementsByClassName('parentCheckbox');
    //   let res= false
    //   let selectedParentCheckBoxes = []
    //   parentCheckboxes.forEach((checkbox)=>{
    //     if(checkbox.checked){
    //       selectedParentCheckBoxes.push(parseInt(checkbox.id))
    //     }
    //   })
    //   console.log(selectedParentCheckBoxes);
    //   console.log(selectedParentCheckBoxes.length == this.services.length);
    //   if(selectedParentCheckBoxes.length == this.services.length){
    //     console.log(selectedParentCheckBoxes);
    //     res = true
    //   }else{
    //     res = false
    //   }
    //   return res
    // },
    checkIfAllChildrenSelected(service_id){
      let res =  false
      this.services.forEach((s)=>{
        if(s.id == service_id){
           if(s.children.length == s.selected_children.length){
            res = true
          }
        }
      })
      //this.checkIfAllParentSelected()
      return res
    },
    checkIfInselectedChildren(c, sc) {
      let res = false;
      if (sc.includes(c)) {
        res = true;
      }
      return res;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      // this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    selectAllChildren(e, service_id){
      if (this.services.length) {
        this.services.forEach((m) => {
          if (m.id === service_id) {
            if (m.children.length) {
              if (e.target.checked) {
                  m.selected_children = []
                  m.children.forEach((c)=>{
                    m.selected_children.push(c.id)
                  })
              } else {
                m.selected_children = []
              }
            }
          }
        });
      }
    },
    // selectAll: function (e) {
    //   let selected = this.services;
    //   let parentCheckboxes =  document.getElementsByClassName('parentCheckbox');
    //   if (e.target.checked) {
    //     parentCheckboxes.forEach((checkbox)=>{
    //       if(checkbox.checked){
    //         checkbox.checked = true
    //       }
    //     })
    //     if(selected.length){
    //       selected.forEach((m) => {
    //         if (m.children.length) {
    //           // if service have children
    //           m.children.forEach((ch)=>{
    //             if (!m.selected_children.includes(ch.id)) {
    //               m.selected_children.push(ch.id);
    //             }
    //           })
    //         }
    //     });
    //     }
    //   } else{
    //     parentCheckboxes.forEach((checkbox)=>{
    //       if(checkbox.checked){
    //         checkbox.checked = false
    //       }
    //     })
    //     if(selected.length){
    //       selected.forEach((m) => {
    //         m.selected_children = []
    //     });
    //     }
    //   }
    // },
    getRoleName(){
      let id  = this.$route.params.id
      let name  = ''
      if(this.roles.length){
        this.roles.forEach((role)=>{
          if(role.value == id){
            name = role.key
          }
        })
      }
      return name
    },
    getRoles(){
      let check = this
      const path = this.$url("USERROLE");
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: check,
      })
        .get(path)
        .then((res) => {
          console.log(res);
          this.roleName = res.data.payload.roleId[0];
          if (this.roleName.length) {
            this.roleName.forEach(function (item) {
              let a = {
                key: item[1],
                value: item[0],
              };
              check.roles.push(a);
            });
            check.isRoleDdEditing = true;
            check.role_label = "Select Role"
          }
        });
    },
    getResult(role = this.$route.params.id) {
      let data = this;
      const path = data.$url("MANAGE_SERVICES");
      data.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: data })
        .get(path)
        .then((res) => {
          //data.isLoading = false;
          let service_data;
          service_data = res.data.payload.operation[0];
          console.log(service_data)
          this.services = []
            let i;
            for (i = 0; i < service_data.length; i++) {
              let tempobj = {
                name: service_data[i].name,
                id: service_data[i].service_id,
                children: service_data[i].children,
                selected_children: service_data[i].selected_children,
                sem_id: service_data[i].sem_id,
                roleId: parseInt(this.$route.params.id),
                service_id: service_data[i].service_id
              };
              this.services.push(tempobj);
            }

          const path = this.$url("MANAGE_SERVICE_ENDPOINT_ROLE_PERMISSION") +  "?q=" +
        role;
        // const path = 'http://0.0.0.0:5056/serviceEndpointPermission' +  "?q=" +
        // role;
          this.$fetch({ requiresAuth: true, operation : 'view', vueScope: data })
            .get(path)
            .then((res) => {
              data.isLoading = false;
              if (res.data.payload.length) {
                data.operationData = []
                console.log(res.data.payload)
                res.data.payload.forEach(function (item) {
                  let a = {
                    role_id: data.$route.params.id,
                    service_id: item.service_id,
                    endpoint_id: item.endpoint_id,
                    endpoint_name: item.endpoint_name,
                    sem_id: item.sem_id,
                  };
                  data.operationData.push(a);

                });
                this.addPermissionData();
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addPermissionData(e) {
      let data = this;
      let services = data.services;
      let permissionData = data.operationData;
      if (permissionData.length) {
        services.forEach((m) => {
          permissionData.forEach((p) => {
            if (m.id == p.service_id) {
              if (!m.selected_children.includes(p.endpoint_id)) {
                m.selected_children.push(p.endpoint_id);
                m.sem_id.push(p.sem_id);
                m.service_id = p.service_id;
              }
            }
          });
        });
      }
    },
    selectedChild(e, serviceId, childId) {
      let selected = this.services;
      let serviceFound = 0;
      if (selected.length) {
        // have some data
        selected.forEach((m) => {
          if (m.id === serviceId) {
            if (m.selected_children.length) {
              // if service have children
              if (e.target.checked) {
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children.splice(
                  m.selected_children.indexOf(childId),
                  1
                );
              }
            } else {
              // no children
              if (e.target.checked) {
                m.selected_children.push(childId);
              }
            }
            serviceFound = serviceFound + 1;
          }
        });
        if (serviceFound == 0) {
          let service = {
            id: serviceId,
            selected_children: [childId],
          };
          selected.push(service);
        }
      } else {
        //no data
        let service = {
          id: serviceId,
          selected_children: [childId],
        };
        selected.push(service);
      }
      console.log(selected);
    },
    submitPermissions(e) {
      e.preventDefault();
      let data = this;
      data.isLoading = true;
      //data.$url("MANAGE_SERVICE_ENDPOINT_ROLE_PERMISSION")
      //https://dev02.competiscan.com:5057/serviceEndpointPermission
      //http://0.0.0.0:5056/serviceEndpointPermission
      this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
        .put(data.$url("MANAGE_SERVICE_ENDPOINT_ROLE_PERMISSION"), data.services)
        .then((result) => {
          data.isLoading = false;
          if (result.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Permission Updated",
              text: result.data.payload.message,
            });
          }
          this.getResult(this.$route.params.id);
          this.reset();

          console.log(result);
        });
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
        this.getResult();
        this.getRoles();
      }
    },
 },
};
</script>
<style  scoped>
/*
 Only custom marker for summary/details
 For cross browser compatible styling hide Firefox's marker by setting summary { display: block }
 and Chrome and Safari's marker by setting ::-webkit-details-marker {display: none;}
*/
summary {
  display: block;
  cursor: pointer;
  outline: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: #ccc;
  position: relative;
}
.tree-nav__item.is-expandable::before {
  content: "";
  height: 100%;
  left: 0.8rem;
  position: absolute;
  top: 2.4rem;
  height: calc(100% - 2.4rem);
}
.tree-nav__item .tree-nav__item {
  margin-left: 2.4rem;
}
.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
  font-family: "ionicons";
  transform: rotate(180deg);
  text-decoration: none;
  content: "-";
}
.tree-nav__item.is-expandable > .tree-nav__item-title {
  padding-left: 2.4rem;
}
.tree-nav__item.is-expandable > .tree-nav__item-title::before {
  position: absolute;
  will-change: transform;
  transition: transform 300ms ease;
  font-family: "ionicons";
  color: #000;
  font-size: 1.1rem;
   text-decoration: none;
  content: "+";
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  color: #000;
  font-size: 1rem;
}
summary.tree-nav__item-title{
  font-size:19px;
  /* text-decoration:underline; */
  line-height: 2rem;
}
.tree-nav__item-title .icon {
  display: inline;
  padding-left: 1.6rem;
  margin-right: 0.8rem;
  color: #666;
  font-size: 1.4rem;
  position: relative;
}
.tree-nav__item-title .icon::before {
  top: 0;
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title::-webkit-details-marker {
  display: none;
}
</style>
